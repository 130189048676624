<template>
<div>
    <div class="top">
        <SearchTop />

    </div>
    <div class="tblup">
        <div class="card_tit">
            商品信息
        </div>
        <div v-for="(item,index) in list" :key="index+'_'+item.OD_ID">
            <div class="glotbl">
                <div class="totalWrp">
                    <img :src="item.Product_ImageUrl?domin+item.Product_ImageUrl:'../assets/standImg.jpg'" />

                    <div class="tbTits">
                        <div class="tb-tit">{{item.OD_Name}}</div>
                        <div class="tb-mod"> {{item.OD_Color}} {{item.OD_Size}}
                            {{item.OD_Variety}}
                        </div>
                    </div>
                    <label>x {{item.OD_Count}}</label>
                    <label>¥{{item.OD_SellPrice}}</label>
                </div>
                <div class="tbInnr tbCount">
                    <label>配送费</label>
                    <label>¥{{model.Orders_Freight}}</label>
                </div>
            </div>
            <div v-if="item.Product_GroupImage.length>0">

                <div class="groupImage">
                    <img :src="domin+item.Product_GroupImage" />
                    <div class="groupImageText">群二维码</div>
                </div>
            </div>
        </div>

        <!-- <div class="glotbl">

<div class="tbInnr tbCount">

<div></div>

<div>

<text class="ans">合计</text>

<text>¥{{model.Orders_Total}}</text>

</div>

</div>

</div> -->
    </div>

    <div class="card_wrp">
        <div class="card_tit">
            订单信息
        </div>
        <div class="card_main" v-if="!!model">
            <div class="card_lit">
                <label>订单号: </label>{{model.Orders_Number}}
            </div>
            <div class="card_lit"><label>订单状态: </label>{{orderStatu(model.Orders_Status)}}</div>
            <div class="card_lit">
                <label>收件人: </label> {{model.Orders_Receiver.replace(/_/g," ")}}
            </div>
            <div class="card_lit">
                <label>手机号: </label> {{model.Orders_Phone}}
            </div>
            <div class="card_lit">
                <label>金额: </label> &yen; {{model.Orders_Total}} 元
            </div>
            <div class="card_lit">
                <label>创建时间:</label>{{model.Orders_Created}}
            </div>
        </div>
    </div>

    <div>
        <a @click="toActive" class="backFl">
            完成
        </a>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            id: 0,
            type: 0,
            list: "",
            model: ""
        }
    },
    created() {
        this.id = this.$route.params.id
        this.type = this.$route.params.type
        this.getDetails()
    },

    methods: {
        toActive() {
            if (this.type == 0) {
                this.$router.push("/active")
            }else{
                this.$router.push("/shop")
            }
        },
        orderStatu(num) {
            let text = ''
            switch (Number(num)) {
                case 0:
                    text = '未提交';
                    break;
                case 1:
                    text = '待支付';
                    break;
                case 2:
                    text = '待发货';
                    break;
                case 3:
                    text = '待收货';
                    break;
                case 4:
                    text = '待评价';
                    break;
                case 5:
                    text = '待分享';
                    break;
                case 6:
                    text = '交易完成';
                    break;
                case 7:
                    text = '交易关闭';
                    break;
                case 8:
                    text = '退款中';
                    break;
                case 9:
                    text = '退款成功';
                    break;
                case 10:
                    text = '退款拒绝';
                    break;
                default:
                    text = '未知'
            }
            return text
        },
        getDetails() {
            // 获取订单详情
            this.wpost({
                url: '/Ajax/WordWelfare/getordersdetail',
                data: {
                    id: this.id
                },
                succ: res => {
                    let {
                        list,
                        model
                    } = res
                    this.list = list
                    this.model = model
                    console.log("订单详情:", res)

                }
            })
        }
    }
}
</script>

<style>
.page {
    background-color: #fff;
}

.top {
    background-color: #f97a0a;
}

.backFl {
    display: block;
    width: calc(100% - 24px);
    border-radius: 4px;
    margin: 30px auto;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background-color: #dedede;
}

.glotbl,
.tblup {
    background-color: #fff;
}

.tblup {
    box-shadow: 0 0 6px #eee;
}

.groupImage {
    padding: 20px 0;
    text-align: center;
}

.groupImage img {
    width: 47%
}

.groupImageText {
    padding: 0 0 12px 0;
}

.card_main label {
    margin: 0;
    color: #F97A0A;
    font-size: 11px;
}

.card_main {
    position: relative;
}

.card_tit {
    padding: 12px
}

.card_wrp button {
    margin: 10px 5px;
}

.card_list {
    padding-top: 41px;
    padding-bottom: 60px;
    height: 100vh;
    box-sizing: border-box;
}

.card_intros {
    font-size: 32rpx;
    line-height: 1.6;
    padding: 10px;
    font-weight: bold;
}

.card_wrp {
    margin: 12px;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 0 6px #eee;
}

.tb-tit,
.tb-mod {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card_lit {
    padding: 2px 10px;
}

.card_tit {
    color: #333;
    padding: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    border-bottom: 1px solid #F0F0F0;
    overflow: hidden;
    font-size: 15px;
    margin: 6px 0;
}

.card_main {

    font-size: 14px;
}

.card_main label {
    margin-right: 12px;
    font-size: 13px;
    font-weight: bold;
    color: #333;
}

.totalWrp {
    padding: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.totalWrp img {
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.tblup {
    margin: 12px;
    border-radius: 6px;
    overflow: hidden;
}

.tbTit,
.tbInnr {
    padding: 0 12px;
    box-sizing: border-box;
}

.tbInnr label {
    font-size: 17px;
}

.tbTit {
    position: relative;
    padding-left: 17px;
    color: #fff;
    font-size: 14px;
    line-height: 2;
    background-color: #f97a0a;
}

.tbTit::before {
    content: "";
    display: block;
    height: 18px;
    width: 3px;
    position: absolute;
    left: 6px;
    top: 5px;
    background-color: #fff;
}

.tbInnr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 3.1;
    border-bottom: 1px solid #e9e9e9;
}

.tbInnr label {
    font-size: 14px;
    width: 80px;
}

.tb-mod {
    font-size: 12px;
    margin-top: 5px;
}

.tbTits {
    white-space: nowrap;
    width: 50%;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tbCount,
.tbTits {
    color: #555;
    font-size: 13px;
    font-weight: bold;
}

.tbCount {
    justify-content: space-between;

}

.tbCount .ans {
    color: #a0a0a0;
    font-size: 13px;
    margin-right: 5px;
}

.tbftp {
    align-items: flex-start;
}

.tbInnr input {
    border: none;
    outline: none;
    width: 76%;
    font-size: 16px;
    line-height: 2.1;
    padding-left: 1rem;
    box-sizing: border-box;
}
</style>
